import React from "react";
import { Box, Container, Typography } from '@mui/material';
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout/Layout";


const GazeboPage: React.FC = () => {

  return (
    <Layout title="Gazebo (aka Besedka)">
      <Container maxWidth="md" sx={{ mt: 5, textAlign: 'center' }}>
        <Typography variant="h5" align="center">Gazebo (aka Besedka)</Typography>
        <Typography sx={{ mb: 5, mt: 2 }} color="text.secondary">
          Only HayApe NFT holders will have access to virtual Gazebo.
        </Typography>

        <Typography variant="h4" align="center" sx={{ color: '#81c995' }}>Coming Soon</Typography>

      </Container>
      <Box sx={{textAlign: 'center', mt: 10}}>
        <StaticImage
          src="../images/gazebo/gazebo.jpg"
          placeholder="blurred"
          alt="Virtual Gazebo"
          layout="constrained"
          width={1000}
        />
      </Box>
    </Layout>
  );
};

export default GazeboPage;